import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"

const StyledLogoContainer = styled.div`
  width: 80px;
  height: 80px;

  @media (max-width: 767px) {
    width: 40px;
    height: 40px;
  }
`

const CompanyCard = ({ compName, compTagline, compLink, compLogo, compLogoAlt }) => {
  return (
    <>
      <Link to={compLink ? `/company/${compLink}` : '/companies'}>
        <div className="group relative bg-site-color-brand-1 hover:bg-site-color-brand-3 cursor-pointer flex flex-col md:flex-row md:items-center mt-8 p-6 rounded-2xl">
          {compLogo
            ?
            <StyledLogoContainer className="rounded-full flex items-center justify-center flex-shrink-0">
              <GatsbyImage
                alt={compLogoAlt ? compLogoAlt : ""}
                image={compLogo}
                formats={["auto", "webp", "avif"]}
                className="rounded-full mx-auto"
              />
            </StyledLogoContainer>
            :
            null
          }
          <div className="mt-3 md:mt-0 md:ml-6">
            <h2 className="text-gray-200 group-hover:text-gray-100 text-2xl md:text-3xl font-bold">{compName ? compName : "Company Name"}</h2>
            <p className="text-gray-300 mt-2">{compTagline ? compTagline : "Company Tagline"}</p>
          </div>
          <div className="absolute right-0">
            <button className="bg-indigo-700 text-indigo-100 px-7 py-2 mr-6 font-semibold rounded-md">
              View profile
            </button>
          </div>
        </div>
      </Link>
    </>
  )
}

export default CompanyCard
