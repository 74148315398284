import React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import { getImage } from "gatsby-plugin-image"

import Layout from "../components/Layout"
import Seo from "../components/seo"
import CompanyCard from "../components/common/CompanyCard"

const CompaniesPage = () => {
  const data = useStaticQuery(graphql`
    query CompaniesPageQuery {
      allPrismicCompany(
        limit: 50
      ) {
        edges {
          node {
            id
            uid
            data {
              company_name
              tagline
              logo {
                alt
                gatsbyImageData(
                  width: 80
                )
              }
            }
          }
        }
      }
    }
  `)

  const companies = data.allPrismicCompany.edges

  return (
    <Layout>
      <Seo
        title="Remote crypto, blockchain and web3 startups and companies"
      />

      <div className="max-w-7xl mx-auto px-4 mt-6 mb-24">
        <div>
          <h1 className="text-gray-300 text-xl md:text-2xl tracking-wider font-semibold">Best Crypto Companies To Work For</h1>
        </div>

        <div className="mt-16">
          <h2 className="text-gray-200 text-5xl lg:text-7xl font-black">Remote blockchain startups looking for remote crypto developers</h2>
          <h3 className="mt-10 text-blue-500 text-2xl lg:text-3xl">Search for the best, remote-first crypto companies.</h3>
        </div>

        <div className="my-16">
          <div>
            {companies.map(item => {
              const compLogo = getImage(item.node.data.logo)

              return (
                <CompanyCard
                  compLink={item.node.uid}
                  compName={item.node.data.company_name}
                  compLogo={compLogo}
                  compLogoAlt={item.node.data.logo.alt}
                  compTagline={item.node.data.tagline}
                />
              )
            })}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default CompaniesPage
